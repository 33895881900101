<template>
    <q-drawer v-model="proxy">
        <q-toolbar
            class="bg-primary tw-text-white tw-shadow-md tw-fixed tw-z-10 lg:tw-hidden"
        >
            <q-toolbar-title>
                <NuxtLink to="/" class="tw-text-white hover:tw-text-white">
                    <q-avatar>
                        <ApplicationLogo />
                    </q-avatar>
                    {{ appName }}
                </NuxtLink>
            </q-toolbar-title>
        </q-toolbar>
        <div class="tw-pt-14 lg:tw-pt-0">
            <slot />
        </div>
        <q-separator></q-separator>
        <div
            class="lg:tw-hidden tw-py-7 tw-px-5 tw-text-sm tw-font-normal tw-flex tw-flex-col tw-gap-6"
        >
            <NuxtLink v-for="m in navbar_menu" to="{{$t(m.route_name)}}">{{
                $t(m.name)
            }}</NuxtLink>
        </div>
    </q-drawer>
</template>

<script setup lang="ts">
import ApplicationLogo from "~/components/ApplicationLogo.vue"
import { getAppName } from "~/common/helpers"
import { computed } from "vue"
import { useNavbarMenu } from "~/composables/LefMenu"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    modelValue: boolean
}>()

const emits = defineEmits<{
    (e: "update:modelValue", val: boolean): void
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const navbar_menu = useNavbarMenu()
const appName = getAppName()
const proxy = computed({
    get: () => props.modelValue,
    set: (val) => emits("update:modelValue", val),
})

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>
